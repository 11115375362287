.wallet-details-wrapper {
  margin: 50px auto;
  width: 475px;

  &__title {
    margin-bottom: 40px;
  }

  &__inputBox {
    margin: 15px 0;
  }

  .box-bottom-text {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;

    p {
      cursor: pointer;
    }
  }

  .box-bottom-text-withToggle {
    justify-content: space-between;
  }

  textarea {
    height: 75px;
  }
}
