@import '../../styles/variables';

.products {
  &__wrapper {
    // display: flex;
    // flex-direction: column;
    // width: 80%;
    // margin: 0 auto;
    overflow-x: auto;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .loader_wrapper {
      min-height: 300px;
    }
  }

  &__item {
    display: flex;
    width: 1200px;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-100;
    padding: 5px 30px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color ease-in-out 0.3s;

    &__first_col {
      display: flex;
      align-items: center;
      cursor: pointer;
      // border-bottom: 1px solid #edf2f7;
      width: 1200px;

      &-sm-col {
        width: 180px;
        display: flex;
        align-items: center;
      }

      &-md-col {
        width: 220px;
        display: flex;
        align-items: center;
      }

      &-lg-col {
        width: 225px;
        display: flex;
        align-items: center;
      }

      &-products__status {
        width: 120px;
        text-align: unset;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    div {
      font-size: 14px;
    }

    &.header {
      background-color: transparent;
      padding-bottom: 5px;
      // border-bottom: 1px solid $gray-200;
      border-radius: 0;
    }

    &.header:hover {
      background-color: transparent;
    }
  }

  &__item:hover {
    background-color: #edf2f7;
  }

  &__preview img {
    width: 44px;
    height: 44px;
    object-fit: contain;
    border-radius: 5px;
  }

  &__preview svg {
    width: 44px;
    height: 44px;
    object-fit: contain;
    border-radius: 5px;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 30px;

    &__inputs_container {
      display: flex;

      .input {
        input {
          height: 40px;
        }
      }

      .products-filter-input-select {
        height: 40px;

        .input-select__input-container {
          height: 40px;
        }
      }

      .products-datepicker-button {
        width: 245px;

        .calendar-icon {
          top: 8px;
          right: 8px;
        }

        .react-datepicker-wrapper {
          width: 100%;

          .button-input {
            width: 100%;
            height: 40px;
            font-weight: 700;
            font-family: 'Visby CF Regular', sans-serif;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            padding: 10px 8px;
          }
        }
      }
    }

    .button {
      max-width: 112px;
      height: 44px;
      padding: 0;
    }
  }

  .input {
    margin-right: 20px;
  }

  .input-select {
    margin-right: 20px;
    width: 200px;
  }

  .input::placeholder,
  .input {
    font-family: 'Visby CF Demi Bold', sans-serif;
    line-height: 1;
    font-size: 16px;
    color: $gray-500;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 30px;
    gap: 16px;

    .button {
      max-width: 180px;
      height: 44px;
      padding: 0;
    }

    svg {
      margin-left: 15px;
    }
  }
}

.create-product {
  max-width: 750px;
  width: 100%;

  &__modal {
    max-width: 750px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 41px 37px;
    z-index: 3;

    @media only screen and (max-width: $breakpoint-md-desktop) and (max-height: 950px) {
      max-height: 70vh;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 40px;
      letter-spacing: 0.02em;
    }

    &-overlay {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }

    &-close {
      position: absolute;
      top: 29px;
      right: 25px;
      cursor: pointer;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 8px 0;
    position: relative;
    height: fit-content;

    .image-errors {
      position: absolute;
      bottom: -15px;
    }

    // &:last-child {
    //   margin: 50px 0 0;
    // }
  }

  &__item {
    width: 48%;
    position: relative;

    .label {
      font-size: 12px;
      color: $gray-600;
      margin-bottom: 4px;
      display: inline-block;
      line-height: 1;
    }

    .removeWarrantyIcon {
      position: absolute;
      top: 35px;
      right: 50px;
      font-size: 14px;
      cursor: pointer;
      opacity: 0.5;
    }

    .input,
    .input-select {
      width: 100%;
      margin-bottom: 0;

      p {
        margin: 0 0 4px 0;
        line-height: 1;
      }
    }

    .input-select__placeholder,
    .input-select__single-value {
      font-family: 'Visby CF Regular', sans-serif;
      color: $gray-600 !important;
      letter-spacing: 0.02px;
      font-size: 15px;
    }

    .input-select__single-value {
      color: unset !important;
    }

    textarea {
      min-height: 42px;
      padding: 10px;
    }
  }

  &__item_full {
    width: 100%;

    textarea {
      min-height: 42px;
      padding: 10px;
    }
  }
}

.create-warranty {
  max-width: 750px;
  width: 100%;

  &__modal {
    border-radius: 10px;
    outline: unset;
    max-width: 750px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 32px 37px;
    z-index: 3;

    @media (max-width: $breakpoint-md-desktop) {
      max-height: 70vh;
      overflow-y: auto;
    }

    &-overlay {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100vh;
      overflow: hidden;

      .input-select__placeholder {
        font-family: 'Visby CF Regular', sans-serif;
      }

      .input-select__single-value {
        font-family: 'Visby CF Regular', sans-serif;
        color: #1a202c !important;
      }
    }

    &-close {
      position: absolute;
      top: 29px;
      right: 25px;
      cursor: pointer;
    }

    .button {
      height: 58px;
    }
  }

  &__row-right .input textarea {
    height: 215px;
    padding: 10px;
    line-height: 1.5;
  }

  &__item {
    width: 48%;

    &.fullwidth {
      width: 100%;

      .input textarea {
        height: 100px;
        padding: 10px;
        line-height: 1.2;
      }
    }

    span {
      font-family: 'Visby CF Demi Bold', sans-serif;
      font-size: 12px;
      color: $gray-600;
      margin-bottom: 4px;
      display: inline-block;
      line-height: 1;
    }

    .input,
    .input-select {
      width: 100%;
      margin-bottom: 0;

      p {
        margin: 0 0 4px 0;
        line-height: 1;

        span {
          margin-bottom: 0;
        }
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 22px 0;

    &-left,
    &-right {
      width: 48%;

      .create-warranty__item {
        width: 100%;
      }

      .react-tel-input .form-control {
        width: 100%;
        border-color: $gray-400;
        border-radius: 10px;
      }
    }
  }

  .pagination-container {
    display: flex;
  }
}

.create-warranty__item__period {
  color: #d93737 !important;
  margin-left: 2px;
}

.phone-row-item {
  .react-tel-input {
    .form-control {
      padding-left: 45px;
    }

    .flag-dropdown .selected-flag {
      padding: 0 0 0 8px;
    }
  }
}

.product-description {
  padding-top: 32px;
}
