.UserManagementProfile {
  height: 100%;

  .loader-content {
    height: 70%;
  }

  .info-block {
    .row-item {
      margin-bottom: 24px;

      .name {
        font-weight: 600;
        font-size: 20px;
        color: #0d0e59;
        margin-right: 22px;
      }

      .key {
        font-weight: 700;
        font-size: 12px;
        color: #a0aec0;
        margin-bottom: 5px;

        &.status {
          padding: 6px 8px;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          color: #0d0e59;
        }
      }

      .key-value {
        &:last-child:not(.key-value:first-child) {
          margin-left: 70px;
        }

        .value {
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 0.02em;
          color: #1a202c;
          line-height: 20px;
        }
      }
    }
  }
}
