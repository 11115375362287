.emailContainer {
  padding: 40px 40px 24px;
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 130px;
    margin-top: 34px;
  }

  .input {
    width: 300px;
  }
}
