.oldPasswordContainer {
  width: 400px;
  margin: 120px auto;

  .login__button-wrapper {
    margin-top: 100px;
  }

  .login__link-signup {
    padding: 10px;
  }

  .form-title {
    text-align: center;
  }
}

.disabled {
  cursor: none;
}

.enabled {
  cursor: pointer;
}
