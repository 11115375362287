@import '../../styles/variables';

.login {
  // background-color: $white-default;
  // background-image: linear-gradient(rgba(27, 107, 228, 0.08), $white-default);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  // &__wrapper {
  //   width: 100%;
  // }

  &__title {
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 52px;
    margin-bottom: 20px;
    text-align: center;
    color: $secondary-dark-blue;
  }

  &__description {
    color: $gray-600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 46px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  &__form {
    // width: 100%;
    width: 390px;
    margin: 0 auto 20px;
    padding: 0 10px;

    & .input:last-of-type {
      margin-bottom: 10px;
    }

    .errors {
      position: absolute;
    }

    // & div:last-of-type {
    //    margin: 60px auto 10px;
    // }
  }

  &__button {
    margin-bottom: 32px;
    max-width: 170px;
    height: 58px;
  }

  &__button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__forgot-password {
    color: $primary-blue;
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    display: inline-block;
    background-color: transparent;
    border: none;
    text-align: right;
    padding: 0;
    margin-bottom: 59px;
    margin-top: 8px;
    width: fit-content;
    float: right;
  }

  &__link-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: 'Visby CF Regular', sans-serif;
    border-top: 1px solid #edf2f7;
    padding-top: 50px;

    span {
      font-family: 'Visby CF Demi Bold', sans-serif;
      color: $gray-600;
      display: block;
      margin-bottom: 20px;
      letter-spacing: 0.02em;
    }

    p,
    a,
    button {
      font-family: 'Visby CF Bold', sans-serif;
      color: $primary-blue;
      cursor: pointer;
    }

    p.disabled {
      cursor: default;
      color: $gray-500;
    }
  }
}
