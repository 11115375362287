.modal {
  // max-width: 700px; preprod values
  // height: 500px;
  max-width: 600px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 60px;
  z-index: 3;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  outline: none;

  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  &-close {
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
    color: #718096;
    font-size: 24px;
  }
}

.wrapper {
  width: 100%;
  height: 500px;

  & > div {
    height: 400px;
  }

  :global(.swiper-pagination-horizontal) {
    bottom: 0;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.swiper-pagination-bullet) {
    width: 6px;
    height: 6px;
  }

  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    top: 100%;
    bottom: 0;
    height: 24px;
    display: block;

    &::after {
      content: '';
    }

    z-index: 9999;
    cursor: pointer;
  }

  :global(.swiper-button-next) {
    right: 0;
    background-image: url('../../icons/arrow-right.svg');
  }

  :global(.swiper-button-prev) {
    left: 0;
    background-image: url('../../icons/arrow-left.svg');
  }
}

.swiper-slide {
  width: 580px;
  height: 100%;
}

.slide {
  .image {
    // height: 360px; preprod values
    height: 400px;
    background: #f5f6fa;
    border-radius: 10px;
    width: 520px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  height: 440px;
}

.active-dot {
  background-color: #ff7d00;
  opacity: 1;
}
