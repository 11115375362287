.PublicProduct {
  background: url('../../icons/gradient.png') center no-repeat;
  background-size: cover;
  padding: 40px 0;
  min-height: 100vh;

  .ProductImagesSlider {
    margin: 0 auto;
  }

  .veritise-design {
    position: absolute;
    left: 45px;
    top: 42px;
  }

  .veritise-design-single {
    align-self: center;
  }

  .container {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    z-index: 1;
  }

  &_footer {
    width: 100%;
    max-width: 384px;
    margin-top: 10px;
    padding: 0 10px;

    .flexible {
      width: 100%;
    }
  }

  .footer-title {
    margin: 0 auto;
    max-width: 270px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 20px;
    font-family: 'Visby CF Regular', sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .PublicProduct {
    .veritise-design {
      position: relative;
      left: initial;
      top: initial;
      margin-bottom: 25px;
    }

    .veritise-design-single {
      width: 273px;
      height: 83px;
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      > div {
        max-width: 100%;
      }
    }
  }
}
