.questionsContainer {
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .questionsBlock {
    width: 100%;
    width: 600px;
  }

  button {
    width: 130px;
    margin-top: 34px;
  }
}
