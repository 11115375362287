@use '../../../styles/variables';

.text {
  color: 'inherit';
  text-align: left;

  &_pt {
    padding-top: 4px;
  }

  &_stylesWindows {
    position: relative;
    top: -2px;
  }

  &_nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  &_type {
    &_h1 {
      font-weight: 700;
      font-size: 34px;
      line-height: 38px;
    }

    &_h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
    }

    &_h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }

    &_h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    &_h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    &_subtitle_m {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    &_subtitle_s {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    &_body_l {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &_body_m {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &_caption {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &_fontWeight {
    &_light {
      font-weight: 300;
    }

    &_regular {
      font-weight: 400;
    }

    &_medium {
      font-weight: 500;
    }

    &_light_bold {
      font-weight: 600;
    }

    &_bold {
      font-weight: 700;
    }

    &_bolder {
      font-weight: 800;
    }
  }

  &_textTransform {
    &_lowercase {
      text-transform: lowercase;
    }

    &_uppercase {
      text-transform: uppercase;
    }

    &_capitalize {
      text-transform: none;
    }
  }

  &_align {
    &_left {
      text-align: left;
    }

    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }

    &_justify {
      text-align: justify;
    }
  }

  &_display {
    &_initial {
      display: initial;
    }

    &_block {
      display: block;
    }

    &_inline {
      display: inline;
    }
  }

  &_color {
    // &_primary {
    //   color: colors.$primary;
    // }

    &_dark-primary {
      color: variables.$gray-900;
    }

    // &_light-primary {
    //   color: colors.$light-primary;
    // }

    // &_ultra-light-primary {
    //   color: colors.$ultra-light-primary;
    // }

    // &_orange {
    //   color: colors.$orange;
    // }

    &_red {
      color: variables.$red-alert;
    }

    // &_green {
    //   color: colors.$green;
    // }

    &_black {
      color: variables.$black;
    }

    &_ultra-dark-gray {
      color: variables.$gray-600;
    }

    &_primary-blue {
      color: variables.$primary-blue;
    }

    &_secondary-dark-blue {
      color: variables.$secondary-dark-blue;
    }

    // &_dark-gray {
    //   color: colors.$dark-gray;
    // }

    // &_gray {
    //   color: colors.$gray;
    // }

    &_light-gray {
      color: variables.$gray-400;
    }

    // &_ultra-light-gray {
    //   color: colors.$ultra-light-gray;
    // }

    // &_white {
    //   color: colors.$white;
    // }

    // &_gray-dark {
    //   color: var(--gray-dark);
    // }

    &_gray-darkness {
      color: variables.$gray-500;
    }

    // &_blue-lighter {
    //   color: colors.$blue;
    // }

    &_tertiary-orange {
      color: variables.$tertiary-orange;
    }

    // &_system-error {
    //   color: var(--system-error);
    // }
  }
}
