.input_checkobox {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  border: 1px solid #cbd5e0;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &:checked {
    border-color: #0072fd;
    background-color: #0072fd;
  }

  &:disabled {
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }

  &:disabled + label {
    opacity: 0.6;
    cursor: default;
    -webkit-user-select: none;
  }

  &:not(:disabled):hover {
    border-color: #007dc6;
  }

  &:active:not(:disabled) {
    border-color: #007dc6;
  }

  &:checked::before {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 6px;
    margin-top: 2px;
  }
}
