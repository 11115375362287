.category-form {
  padding: 32px;
  max-width: 407px;

  h1 {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 32px;
  }

  .field {
    span {
      color: #718096;
      font-size: 12px;
    }

    input {
      height: 52px;
      border: 1px solid #cbd5e0;
      border-radius: 10px;
      margin-top: 6px;
    }

    .required {
      color: red;
    }

    .required-input {
      border-color: red;
    }

    .error {
      margin-top: 5px;
      font-size: 12px;
      max-width: 100%;
    }

    textarea {
      height: 76px;
      border: 1px solid #cbd5e0;
      border-radius: 10px;
      padding: 15px;
      font-size: 16px;
    }
  }
}
