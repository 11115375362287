.import-wallet-form-wrapper {
  margin: 120px auto;

  .import-wallet-button-container {
    margin-top: 100px;
    display: flex;
    justify-content: center;

    button {
      width: 145px;
    }
  }
}
