.changeSubscriptionModal {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px 30px;
  min-height: 300px;

  &_busy {
    padding-top: 32px;
  }
}

.modalTitle {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #0d0e59;
  margin-top: 20px;
}

.alertIcon {
  margin: 24px;
  width: 40px;
  height: auto;
}

.description {
  font-weight: normal;
  font-size: 14px;
  color: #718096;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
  text-align: center;
}

.buttonsGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.button {
  height: 40px;
  margin: 0 10px;
  padding: 0 24px !important;
  white-space: nowrap;
}
