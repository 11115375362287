@import '../../styles/variables';

.button {
  width: 100%;
  padding: 20px 24px;
  line-height: 1;
  font-family: 'Visby CF Bold', sans-serif;
  font-size: 16px;
  border-radius: 10px;
  color: #fff;
  border: 1px solid transparent;
  transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s;

  &[disabled] {
    background-color: $gray-300;
    color: $gray-500;
    cursor: default;

    &:hover {
      cursor: default;
    }
  }

  &-primary {
    background-color: $primary-blue;
  }

  &-white {
    color: $primary-blue;
    background-color: transparent;
  }

  &-secondary {
    background-color: $white-default;
    border-color: $purple-100;
    color: $purple-100;

    &:hover {
      color: $black-100;
      border-color: $black-40;
    }
  }

  &-blue-border {
    color: $primary-blue;
    border-color: $primary-blue;
    background-color: $white-default;
  }

  &-alert {
    background-color: $red-alert;
    color: $white-default;
  }

  &:hover {
    cursor: pointer;
  }
}
