@import '../../styles/_variables.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 950px) {
    max-height: 70vh;
  }
}

.modal-content {
  position: relative;
  min-width: 407px;
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  z-index: 999;

  .close {
    position: absolute;
    right: 29px;
    top: 29px;
    cursor: pointer;
    z-index: 20;
  }
}

.modal-costum-content {
  min-height: unset;
  height: 60px;
  display: flex;
  align-items: center;
  max-width: 730px;
  width: 60%;

  .close {
    right: 20px;
    top: 20px;
  }
}
