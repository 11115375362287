.BusyLoader {
  .Loader {
    margin-top: 0 !important;
  }
}

.Billing {
  .subscription-items {
    display: flex;
    justify-content: center;
    margin: 60px;
    min-height: 300px;

    .Loader {
      margin-top: 0 !important;
    }
  }

  .Table {
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }

      td {
        width: 33%;

        &:first-child {
          .flexible {
            justify-content: flex-start;
          }
        }

        &:last-child {
          .flexible {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .switcher-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .month-yearly-text {
      font-weight: bold;
      font-size: 16px;
      color: #718096;
    }

    .yearly-block {
      position: relative;
      display: flex;
      align-items: center;

      .yearly-info {
        position: absolute;
        white-space: nowrap;
        left: 65px;
        font-weight: bold;
        font-size: 12px;
        color: #718096;
      }
    }
  }

  .change-payment-method {
    margin-top: 32px;
    margin-bottom: 52px;
    display: inline-flex;
    flex-direction: column;

    .hiiden-card-info {
      margin-top: 24px;
      margin-bottom: 32px;
      display: flex;
    }

    .payment_button {
      white-space: nowrap;
    }
  }
}
