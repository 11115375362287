.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cardNumber {
  font-weight: bold;
  font-size: 16px;
  color: #718096;
  margin-left: 14px;
}

.noCardText {
  font-weight: 700;
  font-size: 16px;
  color: #718096;
  letter-spacing: 0.02em;
  margin-left: 15px;
}

.addPayment {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  color: #0072fd;
  margin-top: 30px;
  cursor: pointer;

  .addPaymentIcon {
    margin-right: 12px;
    margin-top: -2px;
  }
}

.payment_button {
  margin-top: 30px;
  height: 40px;
  padding: 0 24px !important;
}

.stripe {
  margin-left: 120px;
  margin-right: 25px;
}

.trashIcon {
  cursor: pointer;
}

.descirption {
  font-weight: 400;
  font-size: 14px;
  color: #718096;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
  line-height: 20px;
}

.alertIcon {
  margin: 24px;
}

.deleteCardModal {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px 30px;
  min-height: 300px;

  &_busy {
    padding-top: 32px;
  }
}

.deleteCardModalTitle {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #0d0e59;
  margin-top: 20px;
}

.buttonsStyle {
  height: 40px;
  margin: 0 10px;
  padding: 0 24px !important;
  white-space: nowrap;
}

.addCardModal {
  padding: 0 32px 32px;
  min-height: 300px;

  &_busy {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.inputDivider {
  margin: 0 10px;
  position: relative;
  top: -30px;
}

.securityCardBlock {
  position: relative;

  &_input {
    appearance: none !important;

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-butto {
      -webkit-appearance: none;
    }
  }
}

.securityCardIcon {
  position: absolute;
  top: 50px;
  right: 15px;
}

.addModalButton {
  margin: 20px 0 0;
  height: 40px;
  padding: 0 24px !important;
}
