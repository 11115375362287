.custom-field-form {
  width: 475px;
  padding: 44px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .title {
    position: absolute;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000;
    left: 23px;
    top: 25px;
  }

  button {
    min-width: 118px;
    max-width: max-content;
  }

  .line {
    width: 343px;
  }

  .choiceble {
    width: 343px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__radio_container {
      display: flex;
      align-items: center;

      legend {
        margin-left: 5px;
      }
    }

    .choice {
      width: 40px;
      color: #0072fd;
      height: 40px;
    }
  }

  .rectangle {
    background-color: #edf2f7;
    width: 114px;
    height: 12px;
    border-radius: 3px;
    margin: 6px 0;
  }

  .field {
    position: relative;
    width: 343px;

    legend {
      color: #718096;
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
    }

    .required {
      color: red;
    }

    .input {
      border-radius: 10px;
      border: 1px solid #cbd5e0;
      width: 343px;
      height: 42px;
      margin-top: 6px;
      padding: 15px;
    }

    .input:disabled {
      background: #f7fafc;
    }

    .fakeInput {
      display: flex;
      align-items: center;
      background: #f7fafc;
    }

    .textarea {
      border-radius: 10px;
      border: 1px solid #cbd5e0;
      width: 343px;
      height: 76px;
      margin-top: 6px;
      padding: 15px;
      background: #f7fafc;
    }

    .textarea:disabled {
      background: #f7fafc;
    }
  }

  .button {
    width: 118px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
