.modal {
  max-width: 489px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 60px;
  z-index: 3;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  outline: none;

  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  &-close {
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
    color: #718096;
    font-size: 24px;
  }
}

.owner-info {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__field {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      color: #a0aec0;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }

    .value {
      letter-spacing: 0.02em;
      color: #1a202c;
      font-weight: bold;
      line-height: 24px;
    }
  }
}
