.modalContainer {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  width: 70vw;
  max-width: 1048px;

  :focus-visible {
    outline: none;
  }

  &__preview img {
    width: 44px;
    height: 44px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #eaeaea;
    cursor: pointer;
  }

  &__leftCol {
    width: 65%;

    &__label {
      margin-bottom: 32px;
    }

    &__dateContainer {
      display: flex;
      margin-bottom: 36px;

      div {
        margin-right: 40px;
      }
    }

    &__description {
      margin-bottom: 36px;
    }

    &__userDatails {
      margin-bottom: 40px;
    }

    &__actionButtons {
      display: flex;

      button {
        width: 135px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }
    }
  }

  &__rightCol {
    width: 30%;

    &__email {
      margin-top: 60px;
      margin-bottom: 36px;
    }

    &__phone {
      margin-bottom: 36px;
    }

    &__address {
      margin-bottom: 28px;
    }
  }
}

.modalContainer:focus-visible {
  outline: none;
}
