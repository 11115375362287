@use '../../../styles/variables';

.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
  height: fit-content;
}

.stepNumber {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  border: 1px solid variables.$gray-300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepText {
  margin: 18px 0 5px;
}

.verticalDashed {
  border: 1px dashed #e2e8f0;
  height: 60px;
}
