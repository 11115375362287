.individualProductContainer {
  display: flex;
  // height: 170px;
  margin: 5px auto;
  padding-bottom: 34px;
  background: /* gradient can be an image */ linear-gradient(
      to left,
      #e2e8f0 26%,
      #e2e8f0 100%,
      #e2e8f0 100%,
      #e2e8f0 26%
    )
    left bottom no-repeat;
  background-size: 100% 1px;
  cursor: pointer;
  max-width: 709px;
  width: 100%;

  &__leftCol {
    width: 68px;
    display: flex;
    margin-right: 12px;

    &__imgContainer {
      width: 68px;
      height: 68px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      margin-top: 32px;

      img {
        width: 68px;
        height: 68px;
        object-fit: contain;
        border-radius: 5px;
      }

      svg {
        width: 68px;
        height: 68px;
        object-fit: contain;
        border-radius: 5px;
      }
    }
  }

  &__rightCol {
    padding-top: 44px;

    &__title {
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      h5 {
        margin-right: 20px;
      }
    }

    &__desc {
      letter-spacing: 1px;
      line-height: 20px;
    }

    &__footer {
      margin-top: 12px;
      display: flex;

      &__date {
        display: flex;
        align-items: center;
        margin-right: 20px;

        p {
          margin-left: 5px;
        }
      }
    }
  }
}
