.wrapper {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;

  &_hide {
    padding: 0;
    border: none;
  }
}

.editor {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;

  &_hide {
    padding: 0;
    border: none;
  }
}

.toolbar {
  border: 1px solid #ccc;
  border-radius: 10px;

  &_hide {
    display: none;
  }
}
