@import '../../styles/variables';

.organizationInfo {
  &__wrapper {
    margin: 0 30px;

    &__label {
      margin-bottom: 20px;
    }
  }

  .loader_wrapper {
    min-height: 300px;
  }

  .label-alert {
    display: inline-block;
    padding: 8px;
    background: $error-bg;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    font-family: 'Visby CF Demi Bold', sans-serif;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .label-pending {
    display: inline-block;
    padding: 8px;
    background: $orange-20;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    font-family: 'Visby CF Demi Bold', sans-serif;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  &__notice {
    &-description {
      color: $gray-600;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
    }

    .button {
      margin-bottom: 36px;
      width: 153px !important;
    }
  }

  .button {
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__data {
    max-width: 350px;
    width: 100%;

    &-img-preview {
      margin: 32px 0;
      max-width: 190px;
      height: 190px;
      width: 100%;
      // height: 232px;
      border-radius: 10px;

      .upload-photo-preview-item {
        width: 100%;
      }

      span {
        display: inline-block;
        font-family: 'Visby CF Demi Bold', sans-serif;
        color: $gray-500;
        letter-spacing: 0.02em;
        margin-bottom: 16px;
        line-height: 1;
      }

      img {
        width: 100%;
        height: inherit;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .input-select {
    color: #000 !important;
    font-family: 'Visby CF Regular', sans-serif;

    .input-select__single-value {
      color: #000 !important;
      font-family: 'Visby CF Regular', sans-serif;
    }
  }

  .input[disabled] {
    background-color: #edf2f7;
  }

  .last-input {
    margin-bottom: unset;
  }
}

.back-button {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50px;
  top: 132px;
  cursor: pointer;

  span {
    color: $primary-blue;
    font-family: 'Visby CF Bold', sans-serif;
    margin-left: 0;
    transition: 0.4s;
  }

  svg {
    transform: translateX(0) rotateY(180deg);
    transition: 0.4s;

    path {
      stroke: $gray-500;
    }
  }

  &:hover {
    svg {
      transform: translateX(-10px) rotateY(180deg);
    }
  }
}

.select-hasError {
  .input-select__control {
    border-color: #ea4e6e;
  }
}
