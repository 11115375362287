@import '../../styles/_variables.scss';

.button-input {
  background: none;
  width: auto;
  margin: 0;
  height: 52px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid $gray-400;
  padding: 2px 10px;
}

.DatePickerInput {
  position: relative;
  min-width: 162px;

  .errors {
    margin-top: 10px;
    display: block;
  }

  .calendar-icon {
    position: absolute;
    top: 14px;
    right: 16px;
    // z-index: 2;

    .calendar-info-content {
      position: relative;

      .calendar-info {
        position: absolute;
        font-weight: 600;
        color: #1a202c;
        font-size: 12px;
        line-height: 16px;
        padding: 10px 16px;
        background-color: #edf2f7;
        border-radius: 6px;
        width: 220px;
        z-index: 999;
        left: -20px;
        top: 35px;
        display: none;

        &::before {
          content: '';
          width: 13px;
          height: 13px;
          background-color: #edf2f7;
          display: block;
          position: absolute;
          top: -5px;
          left: 25px;
          transform: rotate(45deg);
        }
      }
    }

    &:hover {
      .calendar-info {
        display: block;
      }
    }
  }

  &.has-error {
    .react-datepicker-wrapper {
      input {
        border-color: #ea4e6e;
      }
    }
  }

  &.with-icon {
    .react-datepicker-wrapper {
      input {
        padding-right: 50px;
      }
    }
  }
}

.react-datepicker {
  border: 1px solid #e2e8f0;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #fff;
    border: none;

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        width: 55px;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        font-family: 'Visby CF Regular', sans-serif;
        color: #718096;
      }
    }

    .custom-header {
      > button {
        background: none;
        border: none;
        cursor: pointer;
      }

      padding: 0 24px;

      .custom-select-block {
        .custom-select {
          position: relative;
          margin: 0 13px;

          .custom-select-value {
            font-weight: 500;
            font-size: 16px;
            font-family: 'Visby CF Regular', sans-serif;
            color: #1a202c;
            cursor: pointer;

            .arrow-down {
              margin-left: 8px;
            }
          }

          .custom-select-options {
            position: absolute;
            padding: 16px;
            border: 1px solid #e2e8f0;
            box-sizing: border-box;
            border-radius: 12px;
            background-color: #fff;
            overflow-y: auto;
            overflow-x: hidden;
            height: 222px;
            top: 22px;

            &.forMonth {
              left: -13px;
            }

            .select-options {
              font-weight: 700;
              font-size: 12px;
              font-family: 'Visby CF Regular', sans-serif;
              color: #718096;
              margin-bottom: 14px;
              width: 100%;

              &:last-child {
                margin-bottom: 0;
              }

              cursor: pointer;

              &.active {
                color: #0072fd;

                &:hover {
                  color: #0072fd;
                }
              }

              &:hover {
                color: #1a202c;
              }
            }
          }
        }
      }
    }
  }

  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected,
      .react-datepicker__day--in-range {
        background-color: #fff;
        color: #0072fd !important;
        font-weight: bold !important;

        &:hover {
          color: #0072fd;
          font-weight: bold;
          background-color: #fff !important;
        }
      }

      .react-datepicker__day--disabled {
        &:hover {
          color: #ccc !important;
        }
      }

      .react-datepicker__day {
        width: 55px;
        height: 36px;
        line-height: 36px;
        margin: 0;
        font-family: 'Visby CF Regular', sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #000;
        background-color: #fff !important;

        &.react-datepicker__day--selected {
          background-color: #fff;
          font-weight: bold;
          color: #0072fd;

          &:hover {
            background-color: #fff;
          }
        }

        &:hover {
          background-color: #fff;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}
