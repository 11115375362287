.gray_border {
  background: /* gradient can be an image */ linear-gradient(
      to left,
      #e2e8f0 26%,
      #e2e8f0 100%,
      #e2e8f0 100%,
      #e2e8f0 26%
    )
    left bottom no-repeat;
  background-size: 100% 1px;
  height: 2px;
}
