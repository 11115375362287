@import '../../../styles/variables';

.timer {
  color: #1a202c;
  font-weight: 700;
  text-align: center;
  opacity: 0;
  transition: 0.4s;
}

.timer.display {
  opacity: 1;
}
