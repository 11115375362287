.detailsStepContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 90px;

  &__warrantyInfoCol {
    width: 50%;
    display: flex;
    flex-direction: column;

    &__dates {
      display: flex;
      justify-content: space-between;
      width: 350px;
    }

    &__label {
      position: absolute;
      left: 0;
      top: 20px;

      &__item {
        width: 156px;
      }
    }

    &__desc {
      margin-top: 36px;
    }
  }

  &__ownerInfoCol {
    width: 45%;

    div {
      margin-bottom: 20px;
    }
  }
}
