@import '../../../../styles/variables';

.confirmCodeContainer {
  width: 400px;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .codeInput {
    padding: 0;
    text-align: center;
    color: $gray-500;
    border: 1px solid $gray-400;
    border-radius: 12px;
    height: 54px;
    width: 54px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .timer {
    margin-bottom: 100px;
    color: #000;
  }

  .resendBlock {
    padding: 10px;
    width: 100%;
    border-top: 1px solid #edf2f7;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .disabled {
      cursor: default;
    }

    .enabled {
      cursor: pointer;
    }

    span {
      font-family: 'Visby CF Demi Bold', sans-serif;
      color: $gray-600;
      display: block;
      margin-bottom: 20px;
      letter-spacing: 0.02em;
    }

    p {
      font-family: 'Visby CF Bold', sans-serif;
      color: #0072fd;
    }
  }

  button {
    width: 130px;
  }
}
