.menu {
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  width: 232px;
  line-height: 140%;
  font-size: 16px;
  font-family: 'Visby CF', sans-serif;
  color: #1a202c;
  padding: 0 !important;
  box-shadow: none !important;

  &__item {
    margin: 8px 18px !important;
    padding: 0 !important;

    &:hover {
      background: transparent !important;
    }
  }

  &__divider {
    margin: 18px !important;
    background: linear-gradient(
      90deg,
      rgba(16, 34, 58, 0.26) 0%,
      #e2e8f0 23.96%,
      #e2e8f0 74.48%,
      rgba(226, 232, 240, 0.26) 100%
    );
  }
}
