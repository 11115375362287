.dropdownMenu {
  position: absolute;
  background-color: #f7fafc; // Tailwind color: bg-gray-100
  width: max-content; // Tailwind width: w-max
  max-height: 13rem; // Tailwind max-height: max-h-52
  overflow-y: auto; // Tailwind overflow-y: overflow-y-auto
  padding-top: 0.75rem; // Tailwind padding-top: py-3
  padding-bottom: 0.75rem; // Tailwind padding-bottom: py-3
  border-radius: 0.375rem; // Tailwind rounded: rounded
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); // Tailwind shadow: shadow-md
  z-index: 10; // Tailwind z-index: z-10

  .list {
    line-height: 2.5rem;
  }

  .listItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0.75rem; // Assuming the default spacing scale where 3 = 0.75rem */
    padding-right: 0.75rem; // Assuming the default spacing scale where 3 = 0.75rem */
    transition: background-color 0.2s;

    &:hover {
      background-color: #e5e7eb; // Tailwind's bg-gray-200 color */
    }
  }
}

.menuBtn {
  min-width: 112px;
}

.menuIconContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 15px;
  border-radius: 8px;
  // border: 1px solid #718096;
}
