.ChangeLogItem {
  margin-bottom: 60px;

  .title {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #1a202c;
  }

  .log-item {
    .log-item-title {
      color: #a0aec0;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.02em;
      margin-top: 20px;
      margin-bottom: 12px;
    }

    .log-item-desc {
      font-weight: 500;
      font-size: 14px;
      color: #1a202c;
      letter-spacing: 0.02em;
      line-height: 140%;
      white-space: pre-line;
    }
  }
}
