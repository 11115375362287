.layout {
  display: grid;
  padding-bottom: 60px;
  background: url('../../icons/gradient.png') center no-repeat;
  background-size: cover;
  max-height: 478px;
  grid-template-columns: auto 100px minmax(320px, 1920px) auto;
  grid-template-areas:
    '. sidebar header .'
    '. sidebar main .';
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
  // height: 120vh;
  max-height: 868px;
}

.content {
  grid-area: main;
  // min-height: 860px;
  min-height: 85vh;
  padding: 0 22px;
}
