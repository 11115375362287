@import '../../styles/variables';

.forgot-password {
  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__description {
    margin-bottom: 60px;
  }

  &__form {
    // max-width: 342px;
    width: 410px;
    border-bottom: none !important;

    // div:last-of-type {
    //   max-width: 175px;
    // }

    .login__button-wrapper {
      margin-top: 40px;

      .forgot__button {
        height: 58px;
      }
    }
  }

  &__timer {
    display: flex;
    justify-content: center;
  }

  &__actions {
    max-width: 376px;
    margin: 0 auto 23px;
  }
}

swd-pin-field {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 32px;

  input {
    color: #000;
  }
}

.enter-code-input {
  padding: 0;
  text-align: center;
  color: $gray-500;
  border: 1px solid $gray-400;
  border-radius: 12px;
  height: 54px;
  width: 54px;

  &:last-of-type {
    margin-right: 0;
  }
}

.forgot-password-link {
  max-width: 410px;
  margin: 0 auto;
}

.back-to-login {
  left: 35px !important;
}
