.InfoTitle {
  position: absolute;
  right: 18px;
  top: 18px;

  .icon-block {
    position: relative;

    .info-icon {
      cursor: pointer;
    }

    .info-desc {
      position: absolute;
      font-weight: 600;
      color: #1a202c;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 16px;
      background-color: #edf2f7;
      border-radius: 6px;
      width: 270px;
      z-index: 999;
      left: -20px;
      top: 35px;
      display: none;

      &::before {
        content: '';
        width: 13px;
        height: 13px;
        background-color: #edf2f7;
        display: block;
        position: absolute;
        top: -5px;
        left: 25px;
        transform: rotate(45deg);
      }
    }
  }

  &:hover {
    .info-desc {
      display: block;
    }
  }
}
