.modalContent {
  padding: 20px;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 100px;
  margin: 0 auto;
  padding: 8px;
  border: 1px dashed #ccc;
  overflow: hidden;
  border-radius: 6px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
