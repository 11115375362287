.inputGroups {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input {
    width: 400px;

    .input-select__value-container {
      .input-select__placeholder {
        font-family: 'Visby CF Regular', sans-serif;
        // color: #718096 !important;
        font-size: 15px;
      }

      .input-select__single-value {
        color: #000 !important;
        font-family: 'Visby CF Regular', sans-serif;
      }
    }

    .input-select {
      color: #000 !important;
      font-family: 'Visby CF Regular', sans-serif;

      .input-select__single-value {
        color: #000 !important;
        font-family: 'Visby CF Regular', sans-serif;
      }
    }

    .react-tel-input {
      .form-control {
        width: 100%;
        transition: 0.4s;
        border: 1px solid #cbd5e0;
        border-radius: 10px;
        height: 52px;
        line-height: 1;
        outline: none;
        padding: 2px 10px;
        padding-left: 45px;
        background: transparent;
      }
    }
  }

  .phone-row-item-with-error {
    .react-tel-input {
      .form-control {
        border: 1px solid #ea4e6e;
      }

      .flag-dropdown {
        border: 1px solid #ea4e6e;
      }
    }
  }

  .login__button {
    height: 58px;
    margin: 60px 0 40px;
  }
}
