.ChangeLog {
  .logs-title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 28px;
  }

  .version-block {
    margin-bottom: 50px;

    .verios-item {
      min-width: 52px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #cbd5e0;
      color: #718096;
      font-weight: 700;
      font-size: 16px;
      margin: 0 8px;
    }
  }
}
