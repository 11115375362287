body {
  margin: 0;
  font-family: 'Visby CF Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Visby CF Bold';
  src: url('./styles/fonts/visby-cf/VisbyCF-Bold.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Bold.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Bold.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Bold Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-BoldOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-BoldOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-BoldOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-BoldOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Demi Bold';
  src: url('./styles/fonts/visby-cf/VisbyCF-DemiBold.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBold.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBold.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Demi Bold Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-DemiBoldOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBoldOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBoldOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-DemiBoldOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Extra Bold';
  src: url('./styles/fonts/visby-cf/VisbyCF-ExtraBold.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBold.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBold.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Extra Bold Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-ExtraBoldOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBoldOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBoldOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-ExtraBoldOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Heavy';
  src: url('./styles/fonts/visby-cf/VisbyCF-Heavy.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Heavy.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Heavy.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Heavy.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Heavy Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-HeavyOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-HeavyOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-HeavyOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-HeavyOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Light';
  src: url('./styles/fonts/visby-cf/VisbyCF-Light.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Light.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Light.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Light.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Light Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-LightOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-LightOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-LightOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-LightOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Medium';
  src: url('./styles/fonts/visby-cf/VisbyCF-Medium.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Medium.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Medium.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Medium.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Medium Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-MediumOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-MediumOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-MediumOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-MediumOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Regular';
  src: url('./styles/fonts/visby-cf/VisbyCF-Regular.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Regular.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Regular.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Regular Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-RegularOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-RegularOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-RegularOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-RegularOblique.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Thin';
  src: url('./styles/fonts/visby-cf/VisbyCF-Thin.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-Thin.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-Thin.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-Thin.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Visby CF Thin Oblique';
  src: url('./styles/fonts/visby-cf/VisbyCF-ThinOblique.woff2') format('woff2'),
    url('./styles/fonts/visby-cf/VisbyCF-ThinOblique.woff2') format('woff'),
    url('./styles/fonts/visby-cf/VisbyCF-ThinOblique.otf') format('opentype'),
    url('./styles/fonts/visby-cf/VisbyCF-ThinOblique.eot') format('embedded-opentype');
}
