.wrapper {
  width: 343px;
  z-index: 100000;

  :global(.swiper-pagination-horizontal) {
    bottom: 0;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.swiper-pagination-bullet) {
    width: 6px;
    height: 6px;
  }

  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    top: 100%;
    bottom: 0;
    height: 24px;
    display: block;

    &::after {
      content: '';
    }

    z-index: 9999;
    cursor: pointer;
  }

  :global(.swiper-button-next) {
    right: 0;
    background-image: url('../../../../icons/arrow-right.svg');
  }

  :global(.swiper-button-prev) {
    left: 0;
    background-image: url('../../../../icons/arrow-left.svg');
  }
}

.slide {
  .image {
    height: 243px;
    background: #f5f6fa;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  height: 274px;
}

.active-dot {
  background-color: #ff7d00;
  opacity: 1;
}
