.Subscription {
  .subscription-items {
    display: flex;
    justify-content: center;

    .Loader {
      margin-top: 0 !important;
    }
  }

  .switcher-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .month-yearly-text {
      font-weight: bold;
      font-size: 16px;
      color: #718096;
    }

    .yearly-block {
      position: relative;
      display: flex;
      align-items: center;

      .yearly-info {
        position: absolute;
        white-space: nowrap;
        left: 65px;
        font-weight: bold;
        font-size: 12px;
        color: #718096;
      }
    }
  }
}

.registration-subscription-step {
  padding-top: 70px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;

  .signup__back-button {
    top: 80px;
    left: 0;
  }
}
