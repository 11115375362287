.intro-kyc {
  &__wrapper {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .verification__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .start-verification__button {
      margin-top: 80px;
      max-width: 180px;
      position: absolute;
    }

    .skip-verification__button {
      margin-top: 220px;
      max-width: 180px;
      position: absolute;
    }
  }
}

.start-verification-icon-block {
  margin-top: 124px;
}
