.ApiKeys {
  display: flex;
  padding-top: 40px;

  .form-content {
    width: 100%;
    max-width: 375px;

    .input-block {
      width: 100%;
      position: relative;

      .input {
        width: 100%;

        input {
          padding-right: 50px;
        }
      }

      .copy-icon {
        position: absolute;
        top: 52px;
        right: 18px;
        cursor: pointer;
        z-index: 10;
      }
    }

    .generate-button {
      width: 170px;
      margin-top: 50px;
    }
  }
}

.GenerateModal {
  width: 600px;
  padding-bottom: 50px;
  min-height: 240px;

  .Loader {
    margin-top: 82px !important;
  }

  .modal-description {
    max-width: 381px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 50px;
    color: #718096;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 20px;
  }

  .buttons-content {
    .button {
      width: 160px;
      margin: 0 8px;
    }
  }
}
