.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;

  &__text {
    position: absolute;
  }

  &__img {
    position: absolute;
    top: -25px;
  }
}
