.history-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 8px 0 10px 12px;
  background: #f7fafc;
  border-radius: 10px;
  margin: 16px 0;

  .blockExplorer {
    color: #0072fd;
    font-weight: 700;
    font-family: 'Visby CF Regular', sans-serif;
    font-size: 16px;
    display: inline-flex;
  }

  &__description {
    font-size: 14px;
    line-height: 140%;
    color: #1a202c;
    letter-spacing: 0.02em;
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: #a0aec0;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
}
