.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .loader_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .verification-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 22px 0 122px;

    :first-child {
      margin-bottom: 15px;
    }
  }

  &__content {
    margin-top: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin: unset;
      font-weight: 900;
      font-size: 52px;
    }

    &__btns {
      margin-top: 87px;
      display: flex;
      flex-direction: column;

      button {
        width: 140px;
        margin-bottom: 20px;
      }
    }
  }
}
