.labelStatus {
  width: 100px;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  font-family: 'Visby CF Demi Bold', sans-serif;
  text-transform: capitalize;
  padding: 4px 8px;
}

.label-registered {
  background: #e6faf7;
}

.label-verified {
  background: #00c5a81a;
}

.label-Deactivated {
  background: #fdecf0;
}

.label-original,
.label-viewed,
.label-authentic,
.label-new {
  background: #d4edf5;
}

.label-purchased {
  background: rgba(0, 197, 168, 0.1);
}

.label-transferred,
.label-Transferred {
  background: #eae8ff;
}

.label-pending,
.label-removed {
  background-color: #fff6ed;
}

.label-expired {
  background: #fdecf0;
  color: #ea4e6e;
}

.label-active {
  background: rgba(0, 197, 168, 0.1);
  color: #00c5a8;
}

.warranty-label-approved {
  background-color: #d8f0ef;
}

.warranty-label-not-active {
  background-color: #e2e8f0;
}

.warranty-label-pending {
  background-color: #fdebd9;
}

.warranty-label-active {
  background-color: #d8f0ef;
}

.warranty-label-declined,
.label-declined {
  background-color: #fdecf0;
}

.warranty-label-expired {
  background-color: #cbcbda;
}
