.NotFound {
  height: 100vh;

  .title-block {
    margin-top: 90px;

    span {
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
      color: #0d0e59;
    }
  }

  .container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-bottom: 150px;

    .empty-image-content {
      position: relative;
      width: 569px;
      height: 184px;

      .empty-screen-title {
        font-weight: 700;
        font-size: 24px;
        color: #0d0e59;
      }

      .empty-screen-subtitle {
        font-weight: 700;
        font-size: 16px;
        color: #a0aec0;
        letter-spacing: 0.02em;
        line-height: 20px;
        text-align: center;
        max-width: 260px;
        margin-top: 8px;
        margin-bottom: 60px;
      }

      .empty-image {
        position: absolute;
        width: 569px;
        height: 184px;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .file-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -20px;
        z-index: 10;
      }

      .empty-info-content {
        z-index: 2;
        position: relative;
        margin-top: 50px;

        .button {
          width: 170px;
        }
      }
    }
  }
}
