.modalContainer {
  padding: 40px 40px 24px;
  border: unset;

  &__title {
    display: flex;
    justify-content: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin: 32px 0 24px;
  }

  &__desc {
    display: flex;
    justify-content: center;
    width: 400px;
    margin-bottom: 32px;

    p {
      text-align: center;
    }
  }

  &__actionBtns {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 130px;
    }
  }
}
