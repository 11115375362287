@import '../../styles/variables';

.signup {
  &__container {
    display: flex;
    align-items: flex-start;
    margin: 0 30px;
    width: 100%;
    position: relative;
    justify-content: center;
  }

  .login__wrapper {
    max-width: 410px;
  }

  .login__form {
    padding: 0 10px;
  }

  .login__password {
    position: relative;
  }

  .password_tooltip_container {
    position: absolute;
    bottom: 10px;
    right: -32px;
  }

  &__agreement {
    display: flex;
    margin: 30px 0 60px;
    justify-content: center;

    span {
      font-size: 12px;
      color: $gray-500;
      font-family: 'Visby CF Demi Bold', sans-serif;

      a {
        color: $primary-blue;
        text-decoration: none;
        font-size: inherit;
      }
    }

    label {
      display: flex;
      align-items: center;
    }

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }

  .button__wrapper {
    margin: 0 auto;
    max-width: 160px;

    .login__button {
      height: 58px;
      margin-bottom: 26px;
    }
  }

  & &-roles__wrapper {
    max-width: 525px;
  }

  &__back-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 0;

    span {
      color: $primary-blue;
      font-family: 'Visby CF Bold', sans-serif;
      margin-left: 15px;
      transition: 0.4s;
    }

    svg {
      transform: translateX(0) rotateY(180deg);
      transition: 0.4s;

      path {
        stroke: $gray-500;
      }
    }

    &:hover {
      svg {
        transform: translateX(-10px) rotateY(180deg);
      }
    }
  }

  & &-verification__wrapper {
    max-width: 600px;
    text-align: center;

    .button {
      max-width: 165px;
      height: 58px;
    }
  }

  .verification__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 190px;
    }

    .button {
      max-width: 160px;
    }
  }

  .forgot-password__timer {
    margin-bottom: 118px;
    color: #000;
  }

  &__roles {
    .individual,
    .business {
      height: 90px;
      box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
      border-radius: 10px;
      background-color: $white-default;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      span {
        font-size: 24px;
        font-family: 'Visby CF Demi Bold', sans-serif;
        color: $secondary-dark-blue;
      }

      svg {
        position: absolute;
        right: 24px;
        top: calc(50% - 12px);
      }
    }

    .user-role {
      &.active {
        border: 1px solid #0072fd;
      }

      .ButtonLoader {
        position: absolute;
        right: 24px;
        top: calc(50% - 8px);

        * {
          border-color: #0072fd transparent #0072fd transparent;
        }
      }

      &:hover {
        box-shadow: 0 10px 20px -2px rgba(50, 50, 71, 0.1);
      }
    }

    .line {
      border-left: 6px solid;
      border-radius: 0 10px 10px 0;
      width: 6px;
      height: 60px;
      margin-right: 18px;
    }

    .individual {
      margin-bottom: 32px;

      .line {
        border-color: $primary-blue;
      }

      svg path {
        stroke: $primary-blue;
      }
    }

    .business {
      .line {
        border-color: $tertiary-orange;
      }

      svg path {
        stroke: $tertiary-orange;
      }
    }
  }

  swd-pin-field {
    max-width: 360px;
    margin: 0 auto 32px;
  }

  .phone-row-item {
    .react-tel-input {
      height: 52px;

      .flag-dropdown .selected-flag {
        padding: 0 0 0 8px;
      }
    }
  }

  .phone-row-item-with-error {
    .react-tel-input {
      .form-control {
        border: 1px solid #ea4e6e !important;
      }

      .flag-dropdown {
        border: 1px solid #ea4e6e;
      }
    }
  }

  .has-error {
    &.DatePickerInput {
      border: none;
    }

    border: 1px solid #ea4e6e;
  }

  & &-profile-details {
    max-width: 525px;

    .input {
      max-width: 365px;
      margin: 0 auto 10px;

      .react-tel-input {
        height: 52px;

        .form-control {
          max-width: 365px;
          margin: 0 auto 10px;
          width: 100%;
          transition: 0.4s;
          border: 1px solid #cbd5e0;
          border-radius: 10px;
          font-family: 'Visby CF Regular', sans-serif;
          height: 52px;
          padding-left: 45px;
        }

        .flag-dropdown {
          background: #fff;
          border-radius: 10px 0 0 10px;
          width: 45px;
        }
      }
    }

    .button {
      margin: 80px auto 0;
      max-width: 165px;
      display: block;
    }
  }

  .enter-code-step {
    margin-bottom: unset;

    .timer {
      margin-top: 23px;
    }
  }

  .input-select__value-container {
    .input-select__placeholder {
      font-family: 'Visby CF Regular', sans-serif;
      // color: #718096 !important;
      font-size: 15px;
    }

    .input-select__single-value {
      color: #000 !important;
      font-family: 'Visby CF Regular', sans-serif;
    }
  }

  .input-select {
    color: #000 !important;
    font-family: 'Visby CF Regular', sans-serif;

    .input-select__single-value {
      color: #000 !important;
      font-family: 'Visby CF Regular', sans-serif;
    }
  }

  .org-image-container {
    width: 343px;
    margin: 0 auto;

    p {
      font-size: 12px;
      margin-top: 14px;
      margin-bottom: 4px;
      color: #718096;
      display: inline-block;
      font-family: 'Visby CF Regular', sans-serif;
      font-weight: 700;
    }

    div {
      gap: unset;
    }
  }

  .upload-wrapper {
    width: 343px;
    margin: 0 auto;
  }

  & &-create-organisation {
    max-width: unset;

    .input {
      max-width: 343px;
      margin: 0 auto 13px;
      // ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      //   color:#1A202C;
      //   opacity: 1; /* Firefox */
      // }

      // :-ms-input-placeholder { /* Internet Explorer 10-11 */
      //   color:#1A202C
      // }

      // ::-ms-input-placeholder { /* Microsoft Edge */
      //   color:#1A202C
      // }

      p {
        margin-top: 0;
      }
    }

    .lastinput {
      margin: 0 auto;
    }

    .upload-wrapper {
      margin-bottom: 70px;
    }

    .errors {
      max-width: 343px;
      margin: 0 auto 13px;
    }

    > div:last-of-type {
      max-width: 343px;
      margin: 0 auto 32px;

      p {
        margin-bottom: 8px;
      }
    }

    .button {
      margin: 0 auto;
      display: block;
    }
  }
}

.step_two {
  max-width: 370px;
  margin: 0 auto;
  padding-bottom: unset;
}

.email-text {
  color: $primary-blue;
}

.withMargin {
  margin-bottom: 64px !important;
}

.react-pin-fields-container {
  position: absolute;
  top: 90px;

  input {
    margin-right: 10px;
    color: #1a202c;
    font-family: 'Visby CF Demi Bold', sans-serif;
    font-size: 15px;
  }
}

.react-pin-fields-wrapper {
  margin: 100px 0 60px;
}

.google_autocomplete {
  &_label {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 4px;
    color: #718096;
    display: inline-block;
    font-family: 'Visby CF Regular', sans-serif;
    font-weight: 700;
  }

  &_input {
    border: 1px solid #cbd5e0;
    border-radius: 10px;
    height: 52px;
    padding: 0 10px;
  }
}
