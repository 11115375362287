.custom-fields {
  padding-top: 30px;

  &__common {
    display: flex;
    justify-content: space-between;

    &-search {
      width: 232px;

      input {
        height: 44px;
      }
    }
  }

  .custom-button {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }

  &__table {
    padding-top: 26px;

    table {
      border-collapse: separate;
      border-spacing: 0 1em;
    }
  }
}

.head {
  tr {
    border: none !important;
  }
}

.row {
  background: #f7fafc;

  td {
    font-family: 'Visby CF Regular', sans-serif !important;
    border-bottom: none !important;
    color: #1a202c;
  }

  td:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  td:last-child {
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}

.actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
