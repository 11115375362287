.contantContainer {
  display: flex;
  flex-direction: column;
  width: 800px;
  align-items: center;
  margin: 120px auto;

  img {
    width: 200px;
  }
}

.rectangleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentDesc {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.blueText {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  background: #0072fd;
  padding: 0 2px;
  margin: 0 3px;
}

.greenText {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  background: #30c5a7;
  padding: 0 2px;
  margin: 0 3px;
}

.buttonsContainer {
  button {
    height: 44px;
    width: 180px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
