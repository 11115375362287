.container {
  width: 100%;
  max-width: 340px;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  padding: 0 25px 0 20px;
  margin-right: 36px;

  .currentPlan {
    padding: 17px 24px;
    border: 1px solid #0072fd;
    border-radius: 10px;
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 16px;
    color: #0072fd;
  }

  &:last-child {
    margin-right: 0;
  }

  .planes {
    min-height: 135px;
  }

  .loader {
    margin-top: 0 !important;
  }

  .divider {
    width: 100%;
    height: 6px;
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    margin-top: 30px;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 12px;
    color: #1a202c;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;
    height: 65px;
    margin-bottom: 32px;
    color: #718096;
  }

  .price {
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 16px;
    color: #a0aec0;

    .priceItem {
      font-weight: 900;
      font-size: 45px;
      color: #0d0e59;
      font-family: 'Visby CF Regular', sans-serif;
    }
  }

  .planItem {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .planItemText {
    margin-left: 16px;
    font-weight: 700;
    color: #1a202c;
    font-size: 13px;
  }

  .buttonBlock {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-bottom: 0;
    }
  }
}
