@import '../../shared/styles/pages.scss';

.page-title {
  @include page-title();
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_wrapper {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
