.wallet-creation__warning {
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
}

.wallet-connect-buttons-container {
  .button {
    height: 55px;
  }

  .button-ghost {
    padding: 17px 0;
    margin: 10px auto !important;
  }
}
