.category {
  min-width: 1196px;
  padding: 24px;

  .loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-name {
    color: #0d0e59;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    font-style: normal;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;
    max-height: 600px;
    overflow-y: auto;

    &__item {
      height: 60px;
      background: #f7fafc;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 10px;
    }
  }

  &-select-fields {
    padding-top: 38px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .select {
      margin-top: 6px;

      input {
        height: 34px;
      }
    }

    legend {
      color: #718096;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }

    button {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
    }
  }
}

.category-slect-empty {
  height: 200px;

  & > div > :first-child {
    margin-top: 100px;
  }
}

.products-filter-input-select {
  height: 44px;

  .input-select__input-container {
    height: 44px;
  }
}
