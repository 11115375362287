// colors
$black: #000;
$black-100: #0d2336;
$black-80: #9ea7af;
$black-40: #dee5e8;
$black-20: #f5f7f8;
$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-900: #1a202c;
$primary-blue: #0072fd;
$tertiary-orange: #ff7d00;
$secondary-dark-blue: #0d0e59;
$error-bg: #fdecf0;
$white-default: #fff;
$red-alert: #ea4e6e;
$purple-100: #7361de;
$purple-80: #8c7af7;
$purple-20: #e3dff8;
$pink-100: #a362de;
$pink-80: #bc7af7;
$pink-20: #e7daf2;
$green-100: #2a574c;
$green-80: #6ad7bc;
$green-20: #dbf2ec;
$green-10: #e7fff9;
$orange-100: #a6613a;
$orange-80: #d98436;
$orange-20: #faf3e4;
$red-100: #a63a3a;
$red-80: #d93737;
$red-20: #faf5f5;
$border-color: #dce2e6;
$button-success: #00c5a8;
// colors

// breakpoints

$breakpoint-md-desktop: 1400px;
