.container {
  width: 56px;
  height: 28px;
  position: relative;
  background-color: #0072fd;
  border-radius: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;

  .switch {
    width: 24px;
    height: 24px;
    position: absolute;
    background-color: #fff;
    border-radius: 24px;
    right: 2px;
    top: 2px;
    transition: all 0.4s linear;
  }
}
