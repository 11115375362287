@import '../../shared/styles/pages.scss';

.page-title {
  @include page-title();
}

.product-description {
  padding-top: 32px;
}

.product-history {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product-qr-codes {
  display: flex;
  gap: 100px;
}

.product-page-header {
  position: relative;
  margin-bottom: 50px;

  .back-button {
    left: 0;
    top: 25px;
  }
}
