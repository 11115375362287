@import '../../styles/variables';

.notification__body {
  position: absolute;
  width: 382px;
  max-height: 756px;
  overflow-y: auto;
  padding: 0 20px;
  background-color: $white-default;
  right: 25px;
  top: 45px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(189, 189, 189, 0.25);
  border-radius: 5px;

  &__top_title {
    display: flex;
    padding: 32px 0;
    justify-content: space-between;

    .fitContentButton {
      width: fit-content;
      padding: unset;
    }
  }

  &__item {
    position: relative;
    border-bottom: 1px solid $black-40;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 16px;
    }

    &:last-child {
      border: none;
    }
  }

  &__leftBorder {
    width: 4px;
    height: 100%;
    background-color: $primary-blue;
    border-radius: 10px 0 0 10px;
    position: absolute;
  }

  &__icon {
    position: absolute;
  }

  &__info {
    margin-left: 50px;
  }

  &__viewed_item {
    opacity: 0.5;
  }

  &__title {
    font-family: 'Visby CF Demi Bold', sans-serif;
    line-height: 20px;
  }

  &__description {
    padding: 8px 0;
    font-size: 14px;
    line-height: 20px;
  }

  &__date {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 12px;
    color: $black-80;
    line-height: 16px;
  }
}
