.ApiDescription {
  display: flex;

  .swagger-ui {
    width: 100%;

    .loading {
      display: none !important;
    }
  }
}
