.tableView {
  overflow-x: auto;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.warrantiesTableGrid {
  display: flex;
  border-bottom: 1px solid #edf2f7;
  width: 1200px;

  &__sm_col {
    width: 100px;
    text-align: center;
  }

  &__md_col {
    width: 210px;
    padding: 0 10px;
  }

  .status {
    text-align: start;
  }
}
