.aboutPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0;

  &__text {
    margin: 60px 0 70px;
    width: 600px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    button {
      width: 215px;
      margin-right: 15px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.version {
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 20px;
  color: #a0aec0;
  letter-spacing: 0.02em;
}
