@import '../components/index.scss';
@import './variables';

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #e3efff;
  --toastify-color-success: #00c5a8;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #ea4e6e;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(to right, #00c5a8, #5ac8fa, #007aff, #e3efff, #5856d6, #ea4e6e);
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

input,
textarea {
  width: 100%;
  background-color: transparent;
  font-family: 'Visby CF Regular', sans-serif;
  height: 52px;
  line-height: 1;
  outline: none;
  border: none;
  padding: 2px 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

textarea {
  resize: none;
}

input[type='password'] {
  font-family: 'Visby CF Extra Bold', sans-serif;
}

input.disabled {
  background: $gray-200;
  border-color: $gray-300;
  color: $gray-500;
}

input::placeholder,
textarea::placeholder {
  font-family: 'Visby CF Regular', sans-serif;
  font-size: 14px;
}

textarea::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 transparent;
}

textarea::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid none;
}

input:focus,
textarea:focus {
  border-color: $primary-blue;
}

p {
  font-size: 18px;
  line-height: 160%;
  color: $black-100;
}

.content {
  width: 100%;
  margin: 0 auto;
}

.grid {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-4 {
    width: calc(100% / 3);
  }

  &-8 {
    width: 66.6%;
  }
}

.fullwidth {
  width: 100%;
}

.validation-error {
  font-size: 10px;
  margin: 2px 0;
  color: $red-alert;
}

.toast-success {
  background: $purple-100 !important;
  color: $white-default !important;
}

.errors {
  font-size: 12px;
  color: $red-alert;
  line-height: 16px;
  font-family: 'Visby CF Regular', sans-serif;
  font-weight: 700;
}

.isRequired {
  color: $red-alert;
}

.input-select {
  height: 52px;

  &__control {
    min-height: auto !important;
    height: 100%;
    border-radius: 10px !important;
    background-color: transparent !important;
  }

  &__value-container {
    height: 100%;
  }

  &__placeholder,
  &__single-value {
    color: $gray-500 !important;
    font-family: 'Visby CF Demi Bold', sans-serif;
  }

  &__input-container {
    margin: -4px !important;
    padding: 0;
  }

  //&__menu {
  //  height: 200px;
  //  overflow-y: auto;
  //}
}

.no-data {
  text-align: center;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  width: 100%;
  padding: 20px;
  margin-top: 40px;

  span {
    display: inline-block;
    margin-right: 20px;
  }

  .button {
    width: auto;
  }
}

.password_input-container {
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.modal {
  width: 560px;
  background-color: $white-default;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 24px;

  .input-select__placeholder {
    font-family: 'Visby CF Regular', sans-serif;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  &__close-icon {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__title {
    font-size: 24px;
    font-family: 'Visby CF Demi Bold', sans-serif;
    color: $black;
  }

  &__subtitle {
    font-size: 16px;
    font-family: 'Visby CF Demi Bold', sans-serif;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
    color: $gray-500;
  }

  &__list {
    margin-bottom: 14px;
  }

  .button {
    width: auto;

    &:first-child {
      margin-right: 16px;
    }
  }

  &__actions {
    display: flex;

    &.to-right {
      justify-content: flex-end;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    font-family: 'Visby CF Bold', sans-serif;
  }

  li {
    list-style: none;
    font-size: 14px;
    line-height: 22px;

    svg {
      width: 10px;
      margin: 0 8px 0 4px;

      path {
        stroke: $black-80;
      }
    }
  }
}

.veritise-icon {
  position: absolute;
  left: 30px;
  top: 35px;
}

.auth {
  &__title {
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 52px;
    margin-bottom: 20px;
    text-align: center;
    color: $secondary-dark-blue;
  }

  &__subtitle {
    font-family: 'Visby CF Demi Bold', sans-serif;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $gray-900;
    margin-bottom: 10px;
  }

  &__description {
    font-family: 'Visby CF Demi Bold', sans-serif;
    color: $gray-600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 46px;
    text-align: center;
    letter-spacing: 0.02em;
  }
}

.page {
  &__title {
    font-family: 'Visby CF Demi Bold', sans-serif;
    font-size: 28px;
    text-align: center;
    color: $secondary-dark-blue;
    margin-bottom: 40px;
  }
}

.tableView {
  min-height: 60vh;
}

.sm-col {
  min-width: 80px;
  width: 15%;
}

.md-col {
  min-width: 80px;
  width: 20%;
}

.lg-col {
  min-width: 80px;
  width: 30%;
}

.actions-col {
  display: flex;
  width: 5%;

  .icon-button:hover {
    width: 40px;
    height: 40px;
  }
}

.page-header {
  position: relative;
}

.delete-account-btn {
  display: flex;

  p {
    cursor: pointer;
    margin-left: 12px;
  }
}

.blooredText {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.flexible {
  display: flex;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }

  &.vertical-reverse {
    flex-direction: column-reverse;
  }

  &.jStart {
    justify-content: flex-start;
  }

  &.jEnd {
    justify-content: flex-end;
  }

  &.jCenter {
    justify-content: center;
  }

  &.jAround {
    justify-content: space-around;
  }

  &.jBetween {
    justify-content: space-between;
  }

  &.aStart {
    align-items: flex-start;
  }

  &.aEnd {
    align-items: flex-end;
  }

  &.aCenter {
    align-items: center;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.noWrap {
    flex-wrap: nowrap;
  }

  &.grow {
    flex-grow: 1;
  }

  &.noGrow {
    flex-grow: 0;
  }

  &.shrink {
    flex-shrink: 1;
  }

  &.noShrink {
    flex-shrink: 0;
  }
}

.withoutScroll {
  overflow-x: hidden;
}

.Visby_Bold {
  font-family: 'Visby CF Bold', sans-serif;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.whole-page-loading_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.react-tel-input {
  height: 52px;

  .form-control {
    transition: 0.4s;
    border: 1px solid #cbd5e0;
    border-radius: 10px;
    font-family: 'Visby CF Regular', sans-serif;
    height: 52px;
  }

  .country-list {
    width: 365px;

    .country {
      padding: 11px 0;
      margin: 11px 35px 11px 40px;
      border-bottom: 1px solid #e2e8f0;
      font-family: 'Visby CF Regular', sans-serif;
      color: #1a202c;
    }
  }

  .selected-flag:hover {
    background-color: unset;
  }

  .flag-dropdown.open .selected-flag {
    background: unset;
  }
}

.fullWidth {
  width: 100%;
}

.buttons-block {
  position: absolute;
  right: 0;
  top: 0;

  button {
    width: 160px;
    margin-left: 24px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Toastify {
  .Toastify__toast {
    min-height: 58px;
    border-radius: 8px;

    .Toastify__close-button {
      color: #718096;
    }

    .Toastify__toast-body {
      font-weight: 700;
      font-size: 16px;
      font-family: 'Visby CF Regular', sans-serif;
      letter-spacing: 0.02em;

      .Toastify__toast-icon {
        margin-right: 15px;
      }
    }

    &--error {
      background-color: #fdecf0;

      .Toastify__toast-body {
        color: #e84365;
      }

      .Toastify__progress-bar {
        background-color: #e84365;
      }
    }

    &--success {
      background-color: #e5f9f6;

      .Toastify__toast-body {
        color: #00c5a8;
      }

      .Toastify__progress-bar {
        background-color: #00c5a8;
      }
    }

    &--info {
      background-color: #e3efff;

      .Toastify__toast-body {
        color: #0072fd;
      }

      .Toastify__progress-bar {
        background-color: #0072fd;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.authenticated-page-title {
  margin-top: 0 !important;
}

.with_padding {
  padding-right: 343px;
}

.privacy-back {
  top: 60px;
}
