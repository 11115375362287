.products__wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
