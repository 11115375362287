@import '../../styles/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 69px;

  &__user {
    display: flex;
    position: relative;
    padding: 0 32px 0 0;

    &-name {
      font-size: 16px;
      font-family: 'Visby CF Demi Bold', sans-serif;
      letter-spacing: 0.02em;
      margin-right: 32px;
      color: #1a202c;
      font-weight: bold;
    }

    &-notifications {
      &:hover {
        cursor: pointer;
      }

      .badge {
        background: #ff7d00;
        border: 1px solid $white-default;
        color: $white-default;
        font-family: 'Visby CF', sans-serif;
        font-weight: bold;
      }
    }
  }
}

.notification {
  &__overlay {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
