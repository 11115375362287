.UserManagement {
  .BusyLoader {
    min-height: 300px;
  }

  .actions {
    position: relative;

    svg {
      cursor: pointer;
      margin: 0 7px;
    }
  }

  .search-filter-block {
    margin-bottom: 36px;

    .search-input {
      position: relative;
      width: 340px;

      .search-tooltip {
        background: #edf2f7;
        border-radius: 6px;
        font-weight: 800;
        font-size: 12px;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 0 16px;
        position: absolute;
        letter-spacing: 0.2em;
        top: 64px;
        z-index: 10;

        &::before {
          content: '';
          width: 13px;
          height: 13px;
          background-color: #edf2f7;
          display: block;
          position: absolute;
          top: -5px;
          left: 25px;
          transform: rotate(45deg);
        }
      }

      .input {
        margin-bottom: 0;

        input {
          padding-right: 40px;
        }
      }

      .search-icon {
        position: absolute;
        right: 12px;
        top: 14px;
      }
    }

    .button {
      width: auto;
      height: 42px;
      padding: 0 24px;

      .filter-icon {
        margin-left: 12px;
      }
    }
  }
}
