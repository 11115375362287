.noOrganizationContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 100px auto;
  align-items: center;

  button {
    width: 210px;
    margin-top: 50px;
  }
}
