.clipWrap {
  padding: 10px;
}

.clipEach,
.clipEach_odd {
  width: 800px;
  height: 280px;
  position: relative;
}

.clipEach::after,
.clipEach_odd::after {
  content: '';
  position: absolute;
  background: #fff;
}

.clipEach,
.clipEach::after {
  clip-path: polygon(8% 5%, 98% 3%, 95% 100%, 10% 100%);
}

.clipEach_odd,
.clipEach_odd::after {
  clip-path: polygon(5% 0%, 100% 5%, 95% 96%, 10% 100%);
}

.absoulte {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderStyle {
  background-color: #e2e8f0;
}

.borderStyle::after {
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
}
