.product-creation-container {
  margin: 0 auto 30px;
  min-height: 70vh;
  height: unset !important;

  .input-select__indicator-separator {
    background-color: unset;
  }
}

.create_container {
  flex-direction: column;
}

.custom_fields-title {
  margin: 10px 0;
}

.create-product-upload {
  width: 100vw !important;

  p {
    font-size: 12px;
    color: #718096 !important;
  }

  & > div {
    align-items: flex-start !important;
  }
}

.product-create-label {
  font-weight: 700;
  font-family: 'Visby CF Regular', sans-serif;
}

.product-create-input {
  height: 52px;
  line-height: 22.4px;

  .input-select__control:hover {
    border-color: hsl(0, 0%, 80%);
  }
}

.progressBar_container {
  width: 200px;
  border: 1px solid #0072fd;
  background: #fff;
}

.progressBar_progress {
  height: 10px;
  background: #0072fd;
}
