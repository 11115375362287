.questionsContainer {
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loaderWrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mainLabel {
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 52px;
    margin-bottom: 40px;
    text-align: center;
    color: #0d0e59;
  }

  .label {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 4px;
    color: #718096;
    display: inline-block;
    font-family: 'Visby CF Regular', sans-serif;
    font-weight: 700;

    span {
      color: #d93737;
    }
  }

  .questionsBlock {
    width: 100%;
    max-width: 600px;
    margin-bottom: 8px;
  }

  button {
    width: 130px;
    margin-top: 34px;
  }
}
