.container {
  width: 100%;
  border-bottom: 1px solid #edf2f7;
}

.tabs {
  max-width: max-content;
  height: 33px;
  position: relative;
  display: flex;
  gap: 26px;

  &-tab {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 140%;
    color: #a0aec0;

    &.active {
      font-weight: bold;
      // line-height: 24px;
      color: #0d0e59;
    }
  }

  .active-line {
    position: absolute;
    bottom: 0;
    transition: 300ms;
    height: 4px;
    background-color: #0072fd;
    border-radius: 100px 100px 0 0;
  }
}
