@import '../../styles/variables';

.errors {
  font-size: 12px;
  color: #ea4e6e;
  line-height: 16px;
  font-family: 'Visby CF Regular', sans-serif;
  font-weight: 700;
  margin-top: 7px;
  display: block;
}

.upload-wraper {
  width: 100%;

  &__label {
    color: #a0aec0;
    letter-spacing: 0.02em;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 24px;
  }

  &__productModalLabel {
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 12px;
    color: #718096;
    margin-bottom: 4px;
    display: inline-block;
    line-height: 1;
  }
}

.image-upload {
  height: 190px;
  width: 100%;
  overflow-x: hidden;
  border-radius: 8px;
  display: flex;
  // align-items: center;
  gap: 20px;
  // overflow-x: auto;

  &__item {
    position: relative;
    min-width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-400;
    border-radius: 8px;
    margin-top: 7px;

    &-add {
      background: #fff;
      border: 1px dashed #a0aec0;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
    }

    &-hasError {
      border-color: #ea4e6e;
      position: relative;
      width: 160px;
    }

    &-close {
      position: absolute;
      top: -8px;
      right: 0;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  &__preview_items_container {
    display: flex;
    flex-wrap: wrap;

    .image-upload__item {
      margin-right: 5px;
    }
  }
}

.image-upload__fromProductModal {
  flex-direction: column;
  padding-top: 7px;
  height: unset;
}

.image-upload__item_160 {
  width: 160px;
}

.image-upload__hiddenOverflow {
  overflow-x: hidden;
}

.image-contain {
  object-fit: contain !important;
}
