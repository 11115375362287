.swiper-button-next,
.swiper-button-prev {
  display: none;
}

.swiperStyle {
  height: 330px;
  width: 100vw;

  .swiper-pagination {
    width: 800px;
  }

  .active-dot {
    background: #0072fd;
    opacity: 1;
  }
}
