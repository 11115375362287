.BusyLoader {
  position: relative;

  &.isBusy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 65%);
    z-index: 99999;
    top: 0;
    left: 0;
  }

  .Loader {
    display: flex;
    justify-content: center;
    margin-top: 150px;
  }
}
