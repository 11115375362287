.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 60px 0;
  position: relative;

  &__menu {
    margin-top: 40px;
    position: relative;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 0;
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;

    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // height: 60px;
      // width: 78px;
      // padding-bottom: 30px;
      height: 24px;
      width: 78px;
      margin-bottom: 30px;
      cursor: pointer;
      /* stylelint-disable no-descending-specificity */
      &.needStrokeStyle {
        &.needStrokeStyleActive {
          svg {
            path {
              stroke: #0072fd;
              fill: none;
            }
          }
        }

        &:hover {
          path {
            stroke: #0072fd;
            fill: none;
          }
        }
      }

      &.active,
      &:hover {
        path {
          fill: #0072fd;
        }
      }

      &-left-border {
        position: absolute;
        left: -11px;
        height: 39px;
        width: 6px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        &.active-border {
          background-color: #0072fd;
        }
      }
    }
  }

  &__logout {
    display: flex;
    height: 100%;
    align-items: flex-end;
    cursor: pointer;
    // position: absolute;
    // bottom: 30px;

    &:hover {
      path {
        fill: #0072fd;
      }
    }
  }
}

.MenuModal {
  position: absolute;
  padding: 10px 16px 0;
  background-color: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  width: 250px;
  z-index: 99999;

  .closeIcon {
    cursor: pointer;
  }

  .swtichText {
    font-weight: 700;
    font-size: 14px;
  }

  .activeItem {
    color: #0072fd;
  }

  .menuItem {
    margin: 15px 0;
    display: flex;
    align-items: center;

    .menuItemName {
      font-weight: 600;
      font-size: 16px;
    }

    .iconBlock {
      width: 35px;
      height: 35px;
      border-radius: 10px;
      background-color: #0072fd;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 22px;

      .menuCardIcon {
        path {
          fill: #fff;
        }
      }

      .menuCVIcon {
        path {
          stroke: #fff;
        }
      }
    }
  }
}
