.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  height: calc(100vh - 147px);

  .settingsWrapper {
    max-width: 710px;
    width: 100%;

    .flagStyle {
      width: 30px;
    }

    .email_field {
      white-space: nowrap;
    }

    .buttonStyle {
      white-space: nowrap;
      width: auto;
      min-width: 183px;
    }

    &__statusContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      &__messageContainer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 700px;

        button {
          width: 164px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__walletContainer {
      padding-bottom: 25px;

      &__balance {
        display: flex;

        :first-child {
          margin-right: 10px;
        }
      }

      &__buttons {
        display: flex;
        flex-wrap: wrap;

        button {
          width: 190px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 25px;
          margin-right: 5px;
        }
      }
    }

    &__personalInfoContainer {
      margin-top: 30px;

      &__title {
        margin-bottom: 28px;
      }

      &__groupRow {
        display: flex;
        margin-bottom: 28px;

        div {
          margin-right: 40px;
          width: 150px;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 44px;
        margin-bottom: 25px;
      }
    }

    &__langContainer {
      margin-top: 30px;
      margin-bottom: 30px;

      &__title {
        margin-bottom: 28px;
      }

      &__row {
        display: flex;

        &__box {
          display: flex;
          align-items: center;
          margin-right: 60px;

          img {
            margin-right: 13px;
          }
        }
      }
    }

    &__changePassBtnContainer {
      button {
        width: 220px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0;
      }
    }

    &__fullWidth {
      width: 100%;
    }

    &__removeAccountBtnContainer {
      display: flex;
      align-items: center;
      padding: 24px 0 48px;

      p {
        margin-left: 16.25px;
        cursor: pointer;
      }
    }
  }
}

.loader_wrapper {
  min-height: 300px;
}
