@import '../../styles/variables';

.react-tel-input {
  .form-control {
    height: 52px;
    padding-left: 60px !important;
    width: 100%;
  }

  .flag-dropdown {
    background-color: #fff;
    border-radius: 10px 0 0 10px !important;
    width: 53px;
    border-color: $gray-400;

    .selected-flag {
      padding: 0 0 0 13px !important;
    }
  }
}

.input {
  font-family: 'Visby CF Bold', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;

  .lg-input {
    height: 52px;
  }

  .expanding {
    transition: 0s;
    min-height: 74px;
    max-height: 144px;
  }

  input,
  textarea {
    transition: 0.4s;
    border: 1px solid $gray-400;
    border-radius: 10px;
    font-family: 'Visby CF Regular', sans-serif;
    line-height: 25px;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 400;
      font-size: 14px;
      color: #a0aec0;
      line-height: 22px;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 400;
      font-size: 14px;
      color: #a0aec0;
      line-height: 22px;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 400;
      font-size: 14px;
      color: #a0aec0;
      line-height: 22px;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-weight: 400;
      font-size: 14px;
      color: #a0aec0;
      line-height: 22px;
    }

    &.hasError {
      input,
      textarea {
        border-color: $red-alert;
      }
    }
  }

  .textarea {
    position: relative;

    &.with-info {
      textarea {
        padding-right: 50px;
      }
    }
  }

  p {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 4px;
    color: $gray-600;
    display: inline-block;
    font-family: 'Visby CF Regular', sans-serif;
    font-weight: 700;

    span {
      color: $red-80;
    }
  }

  &:last-of-type {
    margin-bottom: 10px;
  }

  //&-with-icon {
  //  padding-left: 32px;
  //  background: url('../../icons/search-icon.svg') center left no-repeat;
  //  background-position-x: 8px;
  //}

  &.error {
    border-color: $red-alert;
  }
}
