.categories {
  padding-top: 30px;

  &__common {
    display: flex;
    justify-content: space-between;

    &-search {
      width: 232px;

      input {
        height: 44px;
      }
    }
  }

  .custom-button {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }
}

.category {
  background: #f7fafc;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 0 24px 0 34px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #1a202c;

  svg {
    cursor: pointer;
  }
}

.categoryName {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryDescription {
  flex: 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryActions {
  width: 40px;
}
