.qr-codes {
  &__item {
    display: flex;
    flex-direction: column;

    &-title {
      color: #1a202c;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
    }

    &-image {
      width: 179px;
      height: 179px;
      object-fit: contain;
    }

    &-link {
      margin-top: 24px;

      legend {
        font-size: 12px;
        line-height: 16px;
        color: #718096;
      }

      .copy-input {
        margin-top: 6px;
        width: 343px;
        height: 52px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;

        input {
          color: #718096;
          font-size: 16px;
          line-height: 24px;
          width: 90%;
        }

        &-icon {
          position: absolute;
          right: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
