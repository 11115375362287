.payments-container {
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  max-width: 1000px;
  padding: 30px 80px;

  .summary-container {
    max-width: 380px;
    background-color: #f4f7f9;
    padding: 25px;
    border-radius: 5px;

    h4 {
      margin-bottom: 20px;
    }

    .total-info {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: bold;
        font-size: 18px;
      }
    }

    button {
      display: flex;
      align-self: center;
      max-width: 300px;
      justify-content: center;
      margin: 20px 0;
    }

    .info-text {
      color: #686f77;
      font-size: 12px;
    }
  }
}

.share-link-info {
  color: #686f77;
  font-size: 14px;
  letter-spacing: 0.5px;

  a {
    color: #0072fd;
  }
}

.StripeComponent {
  margin-top: 30px;
  margin-bottom: 20px;
}

.form-content {
  margin-right: 20px;
}

#payment-element {
  padding: 30px;
  border: 1px solid rgb(113 128 150 / 15%);
  border-radius: 5px;

  > div {
    width: 100%;
  }
}

/* spinner/processing state, errors */
.spinner,
.spinner::before,
.spinner::after {
  border-radius: 50%;
}

.spinner {
  color: #fff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0 auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner::before,
.spinner::after {
  position: absolute;
  content: '';
}

.spinner::before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner::after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0 10.2px;
  transform-origin: 0 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
