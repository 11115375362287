.questionsContainer {
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mainLabel {
    font-family: 'Visby CF Bold', sans-serif;
    font-size: 52px;
    margin-bottom: 40px;
    text-align: center;
    color: #0d0e59;
  }
}
