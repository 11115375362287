@import '../../styles/variables';

.button {
  width: 100%;
  padding: 17px 24px;
  font-family: 'Visby CF Bold', sans-serif;
  font-size: 16px;
  border-radius: 10px;
  color: #fff;
  border: 1px solid transparent;
  transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s;

  &[disabled] {
    background-color: $gray-300;
    color: $gray-500;
    cursor: default;

    &:hover {
      cursor: default;
      background-color: $gray-300 !important;
      color: $gray-500 !important;
    }
  }

  &-primary {
    background-color: $primary-blue;
    transition: background-color ease-in-out 0.3s;
  }

  &-primary:focus {
    background-color: #0954ae;
  }

  &-primary:hover {
    background-color: #2a85f4;
  }

  &-success {
    background-color: $button-success;
  }

  &-primaryOutline {
    background-color: $white-default;
    border-color: $primary-blue;
    color: $primary-blue;

    &:hover {
      color: #fff;
      background-color: $primary-blue;
    }
  }

  &-secondary {
    background-color: $white-default;
    border-color: $purple-100;
    color: $purple-100;

    &:hover {
      color: $black-100;
      border-color: $black-40;
    }
  }

  &-secondary:focus {
    border-color: #0954ae;
    color: #0954ae;
  }

  &-small {
    height: 44px;
    padding: 0 24px;
  }

  &-alert {
    background-color: $red-alert;
    color: $white-default;
  }

  &:hover {
    cursor: pointer;
  }

  &-ghost {
    background-color: transparent;
    color: $primary-blue;
  }

  &-ghost:hover {
    color: #2a85f4;
  }

  &-ghost:focus {
    color: #0954ae;
  }
}
