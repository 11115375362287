.card {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  background: #f7fafc;
  border-radius: 8px;
  height: 60px;

  &__drag-icon {
    cursor: move;
  }

  &__field-name {
    color: #1a202c;
    letter-spacing: 0.02em;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-left: 11px;
  }

  &__trash-icon {
    margin-left: auto !important;
  }
}
