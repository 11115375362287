.kyc {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1;
  }

  iframe {
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100vh;
  }
}
