.product {
  max-width: 100%;

  &__images {
    position: relative;
    width: 343px;
    margin: 0 auto;

    .label {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 99999999999999;
    }
  }

  &__info {
    text-align: center;
    font-size: 28px;

    &_title {
      color: #1a202c;
      font-size: 24px;
      line-height: 32px;
      margin: 4px 0;
      text-align: center;
      font-family: 'Visby CF Regular', sans-serif;
    }

    &_sub-title {
      color: #a0aec0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      margin-bottom: 20px;
      font-family: 'Visby CF Regular', sans-serif;

      img {
        height: 24px;
        width: 24px;
        object-fit: cover;
        border-radius: 4px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &_custom-fields {
      display: flex;
      flex-direction: column;
      padding-top: 16px;

      .custom-field {
        display: flex;
        align-items: center;
        min-height: 20px;
        margin-bottom: 20px;

        &__key {
          color: #1a202c;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          text-transform: capitalize;
          font-family: 'Visby CF Regular', sans-serif;
          margin-bottom: 5px;
          white-space: nowrap;
        }

        &__value {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #1a202c;
          font-family: 'Visby CF Regular', sans-serif;
          margin-bottom: 5px;
        }
      }

      .custom-field-text {
        :first-child {
          max-width: 150px;
          width: 100%;
        }
      }

      .custom-field-textarea {
        flex-direction: column;
        align-items: flex-start;

        :first-child {
          margin-bottom: 4px;
        }
      }
    }

    &_required-fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 16px;

      .required-field {
        &__key {
          color: #a0aec0;
          letter-spacing: 0.02em;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }

        &__value {
          margin-top: 10px;
          white-space: pre-line;
          text-align: left;
        }

        .status {
          padding: 6px 8px;
          background: rgba(0, 197, 168, 0.1);
          border-radius: 6px;
          color: #0d0e59;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-transform: capitalize;
        }

        .owner {
          color: #0072fd;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          letter-spacing: 0.02em;
        }

        .description {
          color: #1a202c;
          letter-spacing: 0.02em;
          // max-width: 612px;
          font-size: 16px;
          line-height: 140%;
          overflow: hidden;
          margin-bottom: 10px;
        }
      }
    }
  }

  .row__title {
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    color: #1a202c;
    margin-bottom: 8px;
    text-align: left;
  }

  .costum_row__title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .additionalInfo_row {
    display: flex;

    &__category {
      margin-right: 77px;
    }

    &__value,
    &__warranty_value {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #1a202c;
    }

    &__warranty_value {
      color: #0072fd;
      cursor: pointer;
    }
  }
}

.kybStatus {
  svg {
    width: 14px !important;
    height: unset !important;
  }
}

.productManufacturer_block {
  &__logo {
    width: 64px;
    height: 24px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
