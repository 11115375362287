.EmployeeAssessment {
  width: 485px;
  min-height: 300px;
  padding: 10px 70px 30px 70px;

  .Loader {
    margin-top: 0 !important;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #718096;
    line-height: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #0d0e59;
  }

  .input {
    width: 100%;

    textarea {
      padding-top: 10px;
      height: 96px;
    }
  }

  .rating-block {
    margin-top: 16px;
    margin-bottom: 32px;

    h3 {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  .send-work {
    width: auto;
    height: 40px;
    padding: 0 40px;
  }
}
