.warrantyRow {
  display: flex;
  background-color: #f7fafc;
  align-items: center;
  border-radius: 8px;
  height: 56px;
  margin: 12px 0 0;
  padding: 0 10px;
  width: 1200px;
  transition: background-color ease-in-out 0.3s;
  cursor: pointer;

  &__sm_col {
    width: 100px;
  }

  &__md_col {
    width: 210px;
  }

  h6 {
    height: 30px;
    margin-right: 120px;
  }

  .warrantyLabel {
    margin-right: 130px;
  }

  &__actionsContainer {
    display: flex;
    justify-content: flex-end;
    width: 120px;
  }
}

.warrantyRow:hover {
  background-color: #edf2f7;
}
