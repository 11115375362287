.infoStepContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.infoStepTitle {
  margin: 32px 0;
}

.stepListContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
