.Table {
  border-top: 1px solid rgb(113 128 150 / 15%);
  border-bottom: 1px solid rgb(113 128 150 / 15%);
  padding-top: 30px;

  .status-value {
    padding: 6px 8px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;

    thead {
      tr {
        th {
          border: none;
          font-weight: normal;
          font-size: 14px;
          color: #718096;
          letter-spacing: 1px;
          padding: 0.3rem 1.1rem;
        }
      }
    }

    tbody {
      tr {
        border-radius: 4px;
        transition: all 0.1s ease;

        td {
          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: none;
          }

          font-weight: normal;
          font-size: 14px;
          text-align: center;
          padding: 0.9rem 1.1rem;
          background-color: #f7fafc;
          border-radius: 8px;
          border: none;

          .flexible {
            font-size: 14px;
            color: #1a202c;
          }

          .user-image {
            border-radius: 5px;
          }

          .resent-invite {
            color: #0072fd;
            cursor: pointer;
            font-weight: 800;
            font-size: 16px;
            text-align: center;
          }

          .email-field {
            font-weight: 400;
            font-size: 14px;
            color: #718096;

            &.active {
              color: #1a202c;
            }
          }

          .rating {
            font-size: 14px;
            color: #1a202c;

            .star-icon {
              margin-top: -2px;
            }

            .star-value {
              margin-left: 10px;
            }
          }

          .download {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0072fd;
            font-weight: bold;

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
