.emptyScanContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .scanImg {
    margin-bottom: 40px;
  }

  &__textContainer {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .marketsContainer {
    img {
      margin: 0 12.5px;
    }
  }
}
