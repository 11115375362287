.ownerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    margin: 70px 0 30px;
  }

  &__infoText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 60px;

    &__textRow {
      display: flex;

      :first-child {
        margin: 0 2px;
      }

      :last-child {
        cursor: pointer;
      }
    }
  }

  &__button {
    width: 220px;
    cursor: pointer;
  }
}
