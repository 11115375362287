.errors {
  font-size: 12px;
  color: #ea4e6e;
  line-height: 16px;
  font-family: 'Visby CF Regular', sans-serif;
  font-weight: 700;
  margin-top: 7px;
  display: block;
}

.costumUploader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 70px;
  position: relative;

  .upload-wraper__label {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 4px;
    color: #718096;
    display: inline-block;
    font-family: 'Visby CF Regular', sans-serif;
    font-weight: 700;
    width: 100%;
  }

  &__infoWrapper {
    display: flex;
    width: 264px;
    height: 70px;
    align-items: center;
    border: 1px dashed #a0aec0;
    border-radius: 8px;
    padding: 10px;

    &__imageRow {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__infoRow {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 10px;
    }
  }

  &__infoWrapper__withErrors {
    border: 1px dashed #ea4e6e;
  }

  .errorBlock {
    position: absolute;
    bottom: -25px;
  }

  .image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a0aec0;
    border-radius: 8px;
    cursor: pointer;
    width: 70px;
    height: 70px;
  }

  .image-upload__item {
    width: 70px;
    height: 70px;
    position: relative;
    border: 1px solid #a0aec0;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 32px;

    svg {
      position: absolute;
      right: -8px;
      top: -8px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }
}
