@import '../../styles/variables';

.wallet-creation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .page-header {
    width: 90%;
  }

  &__form-wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .input textarea {
      height: 96px;
      line-height: 1.5;
      padding: 10px 35px 10px 10px;
    }

    svg {
      position: absolute;
      right: 10px;
      top: 75px;
      cursor: pointer;
    }
  }

  &__warning {
    font-family: 'Visby CF Demi Bold', sans-serif;
    font-size: 12px;
    color: $tertiary-orange;
    margin-bottom: 60px;
  }

  .button {
    max-width: 182px;
    margin: 0 auto;
    display: block;
  }
}

.bloored-button {
  width: fit-content;
  float: right;
  font-size: 16px;
  font-weight: 800;
  margin-right: 4px;
}
